import get from 'lodash/get'
import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap'

import { Container, Col, Row } from 'reactstrap'

import { HomeBrands, PageServices, HomeNewsLetter } from '../components'

import SEO from '../components/seo'

let items;

class pageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      items: null,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const page = get(this, 'props.data.page')
    const path = get(page, 'frontmatter.path')
    const title = get(page, 'frontmatter.title')
    const coverImage = get(page, 'frontmatter.coverImage.childImageSharp.resolutions.src')
    const schemaOrg = get(page, 'frontmatter.schemaOrg')
    const description = get(page, 'frontmatter.description')
    const { activeIndex } = this.state
    items = get(this, 'props.data.files').edges.map(item => item.node.childImageSharp.fluid)

    let slides = null
    if (items.length > 0) {
      slides = items.map((item, index) => {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={index.toString()}
          >
            <Img style={{}} imgStyle={{objectFit: "cover"}} fluid={item} alt={title} />
          </CarouselItem>
        )
      })
    }

    const seoData = {
      title,
      description,
      coverImage,
      path,
      schemaOrg
    }

    try {
      const head = window.document.querySelector("head");
      const script = window.document.createElement("script");

      script.setAttribute("src", 'https://webforms.pipedrive.com/f/loader');
      head.appendChild(script);
    } catch(e) {}

    return (
      <>
        <SEO seoData={seoData} />
        <Container>
          <Row style={{marginBottom: 20}}>
            <Col lg={12} md={12} xs={12}>
              <h1>{title}</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={8} xs={12}>
              {items.length > 0 ? (
                <div className="page-carousel" style={{ marginBottom: 20 }}>
                  <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    pause={true}
                    autoPlay={false}
                  >
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={this.goToIndex}
                    />
                    {slides}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={this.previous}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={this.next}
                    />
                  </Carousel>
                </div>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </Col>
            <Col lg={4} md={8} xs={12}>
              <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6q8k6rB495btDU78yx0kNsPLpaBcQDgVWh4KxEdRBGgvHm9JFO6nInREn5LWD1Nw1d"></div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="text-center"><h2>Nos services</h2>
              <PageServices />
            </Col>
          </Row>
        </Container>
        <HomeBrands />
        <HomeNewsLetter />
      </>
    )
  }
}

export default pageTemplate

export const pageQuery = graphql`
  query PageByPath($path: String!, $sliderImagesDir: String) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
        slides
        description
        schemaOrg
        coverImage {
          childImageSharp {
            resolutions(width: 400) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
    files: allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: $sliderImagesDir } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
